// src/App.js
import React from 'react';
import './App.css';
import Messages from './therapy';
//import Stories from './stories';
import GoogleAuth from './googleauth';
import FacebookAuth  from './facebookauth';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Welcome to a Virtual Therapist using Generative AI.</h1>
        <h3>Please enter your concerns, hit enter and I will provide a response for you.</h3>
        <Messages />
         <div>
           <GoogleAuth />
          </div>
        <div>
          <FacebookAuth />
        </div>

      </header>

    </div>
  );
}

export default App;
