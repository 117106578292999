import React from 'react';
import { GoogleLogin } from '@react-oauth/google';
import { GoogleOAuthProvider } from '@react-oauth/google';
import axios from 'axios';
// Set axios default settings to include cookies
//axios.defaults.withCredentials = true;

const clientId = '142785160028-7b0j02vhkn0ttu0j14djh6okgtrcnm3a.apps.googleusercontent.com'; 

function GoogleAuth() {
  const handleSuccess = async (response) => {
    const idToken = response.credential;
    console.error("id Token:", idToken);
    processToken(idToken);
    };
    const processToken = async (idToken) => {
      try {
        // Send the token to the backend
        const res = await axios.post('/api/google', { idToken: idToken });
        //const res = await fetch('/auth/google', {method: 'POST',headers: {'Content-Type': 'application/json'}, body: JSON.stringify({ idToken: idToken })})
        //const data = await res.json();
        console.error("User:", JSON.parse(res.data.user));
        console.error("Status:", res.data.message);
        // Handle user data (if needed)
        // const data = res.data;
        // console.log('User Data:', data);
        // Here you might want to handle the user data further, e.g., save it to state or context
      } catch (error) {
        // Alert the error message and log the error
        console.error('Error:', error);
      }
    };    
    const handleError = (error) => {
      // Handle errors
      console.error(error);
    };
 
  return (
    <GoogleOAuthProvider clientId={clientId}>
      <GoogleLogin
        onSuccess={handleSuccess}
        onError={handleError}
      />
    </GoogleOAuthProvider>
  );
}

export default GoogleAuth;
