import React from 'react';
import axios from 'axios';
import FacebookLogin from 'react-facebook-login';

const appId = '1574552830126124'; 

const FacebookAuth = () => {
  const handleSuccess = async (response) => {
    const accessToken = response.accessToken;
    console.error("accessToken:", accessToken);
    processToken(accessToken);
    };
    const processToken = async (accessToken) => {
      try {
        // Send the token to the backend
        const res = await axios.post('/api/facebook', { accessToken: accessToken });
        console.error("Status:", res.data.message);
        // Handle user data (if needed)
        // const data = res.data;
        // console.log('User Data:', data);
        // Here you might want to handle the user data further, e.g., save it to state or context
      } catch (error) {
        // Alert the error message and log the error
        //alert('An error occurred while processing the token.');
        console.error('Error:', error);
      }
    };
  const handleError = (error) => {
    // Handle errors
    console.error(error);
    };
  return (
    <FacebookLogin
      appId={appId}
      autoLoad={true}
      fields="name,email,picture.type(large)"
      callback={handleSuccess}
      onFailure={handleError}
    />
  );
};
export default FacebookAuth;