// src/therapy.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookie from 'js-cookie';
// Set axios default settings to include cookies
axios.defaults.withCredentials = true;

function Messages() {
  const [messages, setMessages] = useState([]);
  const [newClientInput, setNewClientInput] = useState('');

  const fetchMessages = async () => {
    try {
      const response = await axios.get('/api/therapy');
      //const sessionid = response.data.sessionid;
      //Cookie.set('sessionid', sessionid.sessionid);  // Store sessionid in cookies
      const tempmessages = response.data.therapyhistory;
      const parsedMessages = tempmessages?.map(tempmessage => JSON.parse(tempmessage)) || [];
      setMessages(parsedMessages);
    } catch (error) {
      console.error('Error fetching messages', error);
    }
  };

  const handleSendInput = async () => {
    if (newClientInput.trim() === '') return;
    try {
      const res = await axios.post('/api/therapy', { content: JSON.stringify({"clientinput" : newClientInput})});
      const sessionid = res.data.sessionid;
      console.error("Session ID:", sessionid);
      Cookie.set('sessionid', sessionid);  // Store sessionid in cookies
      setNewClientInput('');
      fetchMessages();
    } catch (error) {
      console.error('Error sending input', error);
    }
  };

/*  useEffect(() => {
    fetchMessages();
  }, []);
*/  
  return (
    <div>
      <div class="scrollable-div">
        <ul>
          {messages?.map(record => (
            <li key={record.session_id}>
            <strong>Input:</strong> {record.input_text}<br />
            <strong>Model:</strong> {record.model_name}<br />
            <strong>Persona:</strong> {record.persona_id}<br />
            <strong>Response:</strong> {record.response_text}<br />
            </li>
          ))}
        </ul>
      </div>
      <input
        type="text"
        size="50"
        value={newClientInput}
        onChange={(e) => setNewClientInput(e.target.value)}
      /><br></br>
      <button onClick={handleSendInput}>Send</button>  
    </div>
  );
}

export default Messages;
